import { Row } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ChallengeCardCarousel from 'src/components/challenge/ChallengeCardCarousel';
import Link from 'src/components/Link';
import { BuilderChallengeCarousel } from 'src/components/staticComponents/builders';
import { IRootReducers } from 'src/reducers';
import { IContentComponent } from 'src/shared/models';
import { getTranslatedData } from 'utils';
import { getComponentHeader } from '.';

interface Props {
  component: IContentComponent;
}

type ComponentProps = Props & ReturnType<typeof mapStateToProps>;

const ChallengeCarousel = ({ component, user }: ComponentProps) => {
  if (!component.componentDetails || !user) return <React.Fragment />;
  const config = BuilderChallengeCarousel(component, user);
  const {
    challenges,
    classByType,
    description,
    hasDescription,
    hrefPath,
    icon,
    idContentComponent,
    title,
  } = config;

  if (!challenges.length) return <React.Fragment />;

  return (
    <div className="challenge-carousel">
      {title && (
        <Row
          justify="center"
          align="middle"
          className="challenge-carousel--title"
        >
          {getComponentHeader({
            icon,
            title: getTranslatedData(component, 'content') || title,
          })}
        </Row>
      )}
      {hasDescription && (
        <Row
          justify="center"
          align="middle"
          className="challenge-carousel--description"
        >
          <Row className="challenge-carousel--description__content">
            {getTranslatedData(description, 'content') || description.value}
          </Row>
        </Row>
      )}
      <div className={classByType}>
        <ChallengeCardCarousel
          name={`static-page--challenge-carousel--${idContentComponent}`}
          challenges={challenges}
        />
      </div>
      <Row
        className={`container cardImage__trainingLink`}
        justify="center"
        align="middle"
      >
        <Link href={hrefPath}>
          <a className="cardImage__link cardImage__link--active">
            <FormattedMessage id="training.view-all" />
          </a>
        </Link>
        <a className="icon icon--arrow-dropdown-right" />
      </Row>
    </div>
  );
};
const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};

export const ConnectedCarousel = connect(mapStateToProps)(ChallengeCarousel);
