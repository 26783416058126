import { Col, Row } from 'antd';
import { config } from 'config/config';
import routePaths from 'config/routes';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ItemsCarousel from 'react-items-carousel';
import { connect } from 'react-redux';
import Link from 'src/components/Link';
import { IRootReducers } from 'src/reducers';
import { PageDetailEnum } from 'src/shared/enums';
import { IContentComponent, IContentDetail } from 'src/shared/models';
import { ICarouselRedeemPointProduct } from 'src/shared/models/redeemPoints.model';
import { getTranslatedData } from 'utils';
import { getComponentHeader } from '.';

const NUMBER_REDEEM = 4;

interface Props {
  component: IContentComponent;
}

type ComponentProps = Props &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const RedeemPointsCarrousel = ({ component, user }: ComponentProps) => {
  const { formatNumber } = useIntl();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const { componentDetails, icon, title } = component;
  let text: IContentDetail;
  const products: ICarouselRedeemPointProduct[] = [];

  componentDetails.forEach((_product) => {
    if (_product.type === PageDetailEnum.TEXT) {
      text = _product;
    } else {
      products.push(_product.product);
    }
  });

  const renderProduct = (
    product: ICarouselRedeemPointProduct,
    index: number
  ) => {
    if (!product) return;

    return (
      <Col
        className="container-carousel-challenge redeem-points-carrousel"
        sm={22}
        lg={24 / 4}
        key={index}
      >
        <img
          src={product.image ?? config.APP.DEFAULT_IMAGE}
          height="207"
          loading="lazy"
          style={{ borderRadius: '5px', height: 300, objectFit: 'contain' }}
        />
        {renderProductName(product.name)}
        {renderProductDescription(product.description)}
        <Row
          className="product-redeem-point-points"
          align="middle"
          justify="center"
        >
          <FormattedMessage
            id="menu.section.personal.{points}-point"
            values={{ points: formatNumber(product.points) }}
          />
        </Row>
      </Col>
    );
  };

  const renderProductName = (name: string): JSX.Element => {
    if (!name) return <React.Fragment />;

    return (
      <Row
        justify="center"
        align="middle"
        className="product-redeem-point-title"
      >
        <span className="productCard__title__text">{name}</span>
      </Row>
    );
  };

  const renderProductDescription = (description: string): JSX.Element => {
    if (!description) return <React.Fragment />;

    if (description.match('<p>')) {
      return (
        <Row
          justify="center"
          align="middle"
          className="product-redeem-point-description"
        >
          <div
            className="productCard__description__text"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Row>
      );
    }

    return (
      <Row justify="center" align="middle" className="productCard__description">
        <div className="productCard__description__text">
          <p
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></p>
        </div>
      </Row>
    );
  };

  const [carouselGutter, setCarouselGutter] = React.useState(-100);

  useEffect(() => {
    const handleResize = () => {
      let _gutter: number;

      switch (true) {
        case window.innerWidth >= 410:
          _gutter = -190;
          break;
        case window.innerWidth >= 390:
          _gutter = -170;
          break;
        case window.innerWidth >= 370:
          _gutter = -150;
          break;
        case window.innerWidth >= 350:
          _gutter = -130;
          break;
        case window.innerWidth >= 320:
          _gutter = -100;
          break;
        default:
          _gutter = -80;
          break;
      }

      setCarouselGutter(_gutter);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    user &&
    products.length === NUMBER_REDEEM && (
      <div className="container block cardImage" style={{ padding: 0 }}>
        <Row
          justify="center"
          align="middle"
          className="container--no-padding block"
        >
          {getComponentHeader({
            icon,
            title: getTranslatedData(component, 'content') || title,
          })}
        </Row>

        <Row
          justify="center"
          align="middle"
          className="container block"
          style={{ padding: '0 1cm', textAlign: 'center', maxWidth: '800px' }}
        >
          {text && text.value && (
            <Row justify="center" className="regular-body">
              {getTranslatedData(text, 'content') || text.value}
            </Row>
          )}
        </Row>

        <Row
          justify="center"
          gutter={40}
          className="container-carousel-challenge not_show_on_mobile redeem-points-carrousel"
        >
          {products?.map((product, index) => renderProduct(product, index))}
        </Row>

        <div className="imageCarousel show_only_mobile">
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={1}
            gutter={carouselGutter}
          >
            {products?.map((product, index) => renderProduct(product, index))}
          </ItemsCarousel>
        </div>

        <Link href={routePaths.PAGES.REDEEM_POINTS.MAIN}>
          <Row
            className="block container cardImage__trainingLink"
            justify="center"
            align="middle"
          >
            <span className="cardImage__link cardImage__link--active">
              <FormattedMessage id="training.view-all" />
            </span>
            <a className="icon icon--arrow-dropdown-right" />
          </Row>
        </Link>
      </div>
    )
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = null;

export const ConnectedRedeemPointsCarousel = connect(
  mapStateToProps,
  mapDispatchToProps
)(RedeemPointsCarrousel);
