import { Row } from 'antd';
import React from 'react';
import { IconEnum } from 'src/shared/enums';

const {
  LEARN,
  EVENT,
  REQUEST_ORDER,
  DOCUMENT,
  GIFT,
  ADDRESS,
  CAMPAIGN,
} = IconEnum;

export const getComponentHeader = ({
  icon,
  title,
}: {
  icon?: number;
  title?: string;
}) => {
  return (
    <Row justify="center">
      <div className="static-header-icon">{getIcon(icon)}</div>
      {title && (
        <h3
          style={{ fontSize: '35px', textAlign: 'center' }}
          className="static-header-title">
          {title}
        </h3>
      )}
    </Row>
  );
};

export const getIcon = (icon: number) => {
  if (!icon) return;

  let iconClass: string;

  switch (icon) {
    case LEARN:
      iconClass = 'icon--challenge icon__size-120';
      break;
    case EVENT:
      iconClass = 'icon--events icon__size-85';
      break;
    case REQUEST_ORDER:
      iconClass = 'icon--order_2 icon__size-85';
      break;
    case DOCUMENT:
      iconClass = 'icon--marketing-materials icon__size-85';
      break;
    case GIFT:
      iconClass = 'icon--gift_2 icon__size-85';
      break;
    case ADDRESS:
      iconClass = 'icon--map-pin icon__size-85';
      break;
    case CAMPAIGN:
      iconClass = 'icon--campaign-map icon__size-85';
      break;
    default:
      iconClass = 'icon--campaign-map icon__size-85';

      break;
  }
  return (
    <Row justify="center">
      <a className={`icon ${iconClass}`} />
    </Row>
  );
};
